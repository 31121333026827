import React /*, { useEffect, useState }*/ from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid /*, Typography */,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import COLORS from '../constants/colors';
import FONTS from '../constants/fonts';
import { useIntl, Link } from 'gatsby-plugin-intl';
import NAV_ITEMS from '../constants/nav-items';
import {
  COPY_RIGHTS,
  VOW_LINK,
} from '../constants/company-info';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import OrganisationCard from './Cards/OrganisationCard';
import Vow from './Icons/Vow';

const useStyles = makeStyles(theme => ({
  Footer: {
    backgroundColor: COLORS.DARK_BLUE,
    color: `${COLORS.WHITE} !important`,
    width: '100%',
    margin: 0,
    padding: '130px 50px 40px 95px ',
    '& a,p': { 'line-height': 1.4 },
    '& a': {
      width: 'fit-content',
      textDecoration: 'none',
      display: 'inline-block',
      '&:hover': {
        color: `${COLORS.BLUE}`,
      },
    },
    [theme.breakpoints.down('md')]: {
      padding: '40px 35px 40px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 10,
      paddingTop: 50,
    },
    [theme.breakpoints.between('sm', 825)]: {
      fontSize: FONTS.SIZE.XXXS,
    },
  },
  logo: {
    width: 230,
  },
  pagesColumn: {
    fontWeight: 500,
    marginBottom: 40,
    textTransform: 'lowercase',
    [theme.breakpoints.down('sm')]: { marginBottom: 20 },

    '& >*': {
      marginBottom: 8,
    },
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 25,
    '& >*': {
      lineHeight: '0 !important',
    },
    '& svg': {
      fontSize: 45,
      margin: '0 15px',
      [theme.breakpoints.down(820)]: { fontSize: 30 },
      [theme.breakpoints.down('xs')]: { fontSize: 50 },
    },
  },
  customIcon: {
    transform: 'scale(0.75)',
    borderRadius: 5,
    backgroundColor: 'currentColor',
    '&>path': {
      fill: COLORS.DARK_BLUE,
      transform: 'scale(0.8) translate(15%,10%)',
    },
  },
  copyRights: {
    fontSize: FONTS.SIZE.XXXS,
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      display: 'block',
    },
    [theme.breakpoints.between('sm', 825)]: {
      fontSize: FONTS.SIZE.XXXXS,
    },
    display: 'flex',
    '&>div': { marginTop: 'auto' },
  },
  vowLogoContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}));

const Footer = () => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down('xs')
  );
  const data = useStaticQuery(FooterQuery)
    .allContentfulScanshipCompanyInfo.edges[0].node;
  const { facebook, linkedIn, twitter, lightLogo } = data;
  const {
    descriptionShort: { descriptionShort },
    organisationNameNorway,
    organisationNamePoland,
    organisationNameUsa,
    addressNorway,
    addressUsa,
    addressPoland,
    emailNorway,
    emailUsa,
    emailPoland,
    phoneNorway,
    phoneUsa,
    phonePoland,
    organisationNumberNorway,
    organisationNumberUsa,
    organisationNumberPoland,
  } = data;

  const orgCards = [
    {
      name: organisationNameNorway,
      address: addressNorway.addressNorway,
      email: emailNorway,
      phone: phoneNorway,
      orgNumber: organisationNumberNorway,
    },
    {
      name: organisationNameUsa,
      address: addressUsa.addressUsa,
      email: emailUsa,
      phone: phoneUsa,
      orgNumber: organisationNumberUsa,
    },
    {
      name: organisationNamePoland,
      address: addressPoland.addressPoland,
      email: emailPoland,
      phone: phonePoland,
      orgNumber: organisationNumberPoland,
    },
  ];

  const copyRights = (
    <Grid
      item
      xs={12}
      sm={4}
      md={3}
      className={classes.copyRights}
    >
      &copy; {COPY_RIGHTS}
    </Grid>
  );

  return (
    <Grid container className={classes.Footer} spacing={4}>
      <Grid item md={12} lg={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Link to={NAV_ITEMS[0][1]}>
              <Img
                className={classes.logo}
                fluid={lightLogo.fluid}
                alt="logo"
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} lg={9}>
            <p>{descriptionShort}</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10} lg={7}>
        <Grid container spacing={4}>
          {orgCards.map(itemProps => (
            <OrganisationCard
              key={itemProps.name}
              {...itemProps}
              xs={12}
              sm={4}
            />
          ))}
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={2}
        md={2}
        item
        className={classes.pagesColumn}
      >
        {NAV_ITEMS.map(item => (
          <Grid item xs={12} key={item.link}>
            <Link to={item.link}>
              {formatMessage({ id: item.intlId })}
            </Link>
          </Grid>
        ))}
      </Grid>
      {!smallScreen && copyRights}
      <Grid
        item
        xs={12}
        sm={4}
        md={6}
        className={classes.iconsContainer}
      >
        <a href={linkedIn} target="_blank" rel="noreferrer">
          <LinkedInIcon />
        </a>
        <a href={facebook} target="_blank" rel="noreferrer">
          <FacebookIcon />
        </a>
        <a href={twitter} target="_blank" rel="noreferrer">
          <TwitterIcon className={classes.customIcon} />
        </a>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={3}
        className={classes.vowLogoContainer}
      >
        <a href={VOW_LINK} target="_blank" rel="noreferrer">
          <Vow />
        </a>
      </Grid>
      {smallScreen && copyRights}
    </Grid>
  );
};

export default Footer;

const FooterQuery = graphql`
  query FooterQuery {
    allContentfulScanshipCompanyInfo {
      edges {
        node {
          descriptionShort {
            descriptionShort
          }
          facebook
          linkedIn
          twitter
          lightLogo {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          organisationNameNorway
          addressNorway {
            addressNorway
          }
          emailNorway
          phoneNorway
          organisationNumberNorway
          organisationNameUsa
          addressUsa {
            addressUsa
          }
          emailUsa
          phoneUsa
          organisationNumberUsa
          organisationNamePoland
          addressPoland {
            addressPoland
          }
          emailPoland
          phonePoland
          organisationNumberPoland
        }
      }
    }
  }
`;
