import React from 'react';

const MenuBurger = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <g>
        <path d="M4 22H36C36 24.2091 34.2091 26 32 26H4V22Z" />
        <path d="M4 19C4 16.7909 5.79086 15 8 15H36V19H4Z" />
      </g>
    </svg>
  );
};

export default MenuBurger;
