export default {
  PADDING: {
    TOP_PADDING: {
      DEFAULT: 150,
      MD: 140,
      XS: 75,
    },
    RIGHT_PADDING: {
      DEFAULT: 100,
      MD: 40,
      XS: 24,
    },
    BOTTOM_PADDING: {
      DEFAULT: 120,
      MD: 100,
      XS: 90,
    },
    LEFT_PADDING: {
      DEFAULT: 100,
      MD: 40,
      XS: 24,
    },
  },
  DIVIDER: {
    HEIGHT: {
      BOLD: 7,
      REGULAR: 5,
    },
    WIDTH: {
      XL: 100,
      L: 48,
      M: 40,
      S: 32,
    },
  },
};
