import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';
import DefaultCookieConsent, {
  Cookies,
} from 'react-cookie-consent';
import DefaultButton from './Buttons/DefaultButton';
import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../constants/colors';
import FONTS from '../constants/fonts';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: `rgba(20, 20, 20,0.78) !important`,
    padding: 20,
    alignItems: 'center !important',
  },
  text: {
    margin: '0px !important',
    paddingRight: 15,
    fontSize: FONTS.SIZE.XS,
    [theme.breakpoints.down('xs')]: {
      fontSize: FONTS.SIZE.XS,
      maxWidth: '65%',
    },
    [theme.breakpoints.down(285)]: {
      fontSize: FONTS.SIZE.XXS,
      maxWidth: '55%',
    },
  },
  link: {
    fontSize: FONTS.SIZE.XS,
    '&:hover': { color: COLORS.BLUE },
  },
  button: {
    color: `${COLORS.WHITE} !important`,
    backgroundColor: `${COLORS.BLUE} !important`,
    fontSize: FONTS.SIZE.XS,
    fontWeight: `${FONTS.WEIGHT.REGULAR}!important`,
    margin: '0 !important',
    minWidth: 100,
  },
}));

const CookieConsent = () => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  return (
    <>
      {Cookies.get('hasCookiesConsented') !== 'true' && (
        <DefaultCookieConsent
          containerClasses={classes.root}
          contentClasses={classes.text}
          cookieName="hasCookiesConsented"
          //TODO:Check if the following property should be activated
          //cookieSecurity = {true}
          expires={150}
          buttonText={formatMessage({
            id: 'CookieConsent.button',
          })}
          ButtonComponent={DefaultButton}
          buttonClasses={classes.button}
        >
          {formatMessage(
            {
              id: 'CookieConsent.text',
            },
            {
              param1: (
                <Link
                  className={classes.link}
                  to="/privacy-policy"
                >
                  {formatMessage({
                    id: 'CookieConsent.cookies',
                  })}
                </Link>
              ),
            }
          )}
        </DefaultCookieConsent>
      )}
    </>
  );
};

export default CookieConsent;
