import React, { useState, useEffect, useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useLocation } from '@reach/router';
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  // Drawer,
  IconButton,
  // Divider,
  Toolbar,
  Button,
} from '@material-ui/core';
//import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import COLORS from '../constants/colors';
import FONTS from '../constants/fonts';
import { useIntl, navigate } from 'gatsby-plugin-intl';
import NAV_ITEMS from '../constants/nav-items';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import SIZES from '../constants/sizes';
import NavDrawer from '../components/NavDrawer';
import MenuBurger from './Icons/MenuBurger';

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 'fit-content',
    '& > div': {
      maxWidth: SIZES.DIVIDER.WIDTH.M,
      width: '30%',
      borderBottom: `${SIZES.DIVIDER.HEIGHT.REGULAR}px solid ${COLORS.BLUE}`,
    },
  },
})(props => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <div /> }}
  />
));

const useStyles = makeStyles(theme => ({
  navBar: {
    padding: 50,
    paddingLeft: 0,
    position: 'absolute',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
  },
  appBar: {
    position: 'relative',
    margin: '0',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&>.MuiTabs-root': {
      minHeight: 'auto',
    },
  },
  logoTab: {
    maxWidth: 'none',
    opacity: 1,
    padding: '10px 50px',
    backdropFilter: 'blur(4px)',
    backgroundColor: ({ isTransparent }) =>
      !isTransparent && 'rgba(244, 244, 244, 1)',
    '& .MuiTouchRipple-root': {
      color: COLORS.BLUE,
      zIndex: -1,
      [theme.breakpoints.down('sm')]: {
        backgroundColor: ({ isTransparent }) =>
          !isTransparent && 'rgba(244, 244, 244, 0.85)',
      },
    },
  },
  appBarSmallDevice: {
    backgroundColor: 'rgba(248, 248, 248, 0.7)',
    boxShadow: 'none',
    backdropFilter: 'blur(8px)',
    padding: '7px 0',
  },
  tab: {
    minWidth: 'auto',
    padding: '10px 5px',
    margin: '0 12px',
    minHeight: 'auto',
    textTransform: 'lowercase',
    fontWeight: `${FONTS.WEIGHT.MEDIUM} !important`,
    color: ({ lightBackground }) =>
      lightBackground ? COLORS.DARK_BLUE : COLORS.WHITE,
    fontSize: FONTS.SIZE.M,
    opacity: 1,

    '& .MuiTouchRipple-root': {
      color: COLORS.BLUE,
      zIndex: -1,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: FONTS.SIZE.XXS,
      margin: '0 5px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.XXS,
    },
  },
  openButton: {
    color: COLORS.DARK_BLUE,
    marginLeft: 'auto',
    '& svg': {
      transform: 'scale(0.8,1.2)',
    },
    '& .MuiTouchRipple-root': {
      color: COLORS.BLUE,
      zIndex: -1,
    },
  },
  closeButton: {
    display: 'block',
    marginRight: 'auto',
    color: 'inherit',
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '50vw',
      backgroundColor: 'rgba(0, 19, 55, 0.9)',
      color: COLORS.WHITE,
      backdropFilter: 'blur(10px)',
    },
    '& .MuiTabs-indicator': {
      left: 0,
      backgroundColor: COLORS.BLUE,
    },
  },
  drawerTabs: {
    '& .MuiTabs-flexContainer': {
      width: '100%',
    },
  },
  drawerTab: {
    textTransform: 'unset',
    fontSize: FONTS.SIZE.S,
    maxWidth: '100%',
  },
  drawerLogoTab: {
    opacity: 1,
  },
  lightLogo: {
    width: 200,
    maxWidth: '100%',
  },
  logo: {
    width: 200,
    [theme.breakpoints.down('sm')]: {
      width: 180,
    },
  },
}));

const getShownPageIndex = currentPath => {
  for (let index = 1; index < NAV_ITEMS.length; index++) {
    if (currentPath.includes(NAV_ITEMS[index].link)) {
      return index;
    }
  }
  if (currentPath === NAV_ITEMS[0].link) {
    return 0;
  }
  return false;
};

const Nav = () => {
  const data = useStaticQuery(NavQuery);
  const { formatMessage, locale } = useIntl();
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down(750)
  );
  const currentPath =
    useLocation().pathname.indexOf(`/${locale}`) === 0
      ? useLocation().pathname.slice(locale.length + 1)
      : useLocation().pathname;

  const [selectedPage, setSelectedPage] = useState(
    getShownPageIndex(currentPath)
  );
  const classes = useStyles({
    isTransparent: getShownPageIndex(currentPath),
    lightBackground:
      getShownPageIndex(currentPath) === 0 ? false : true,
  });
  const indicatorRef = useRef();

  const logo = !getShownPageIndex(currentPath)
    ? data.allContentfulScanshipCompanyInfo.edges[0].node
        .logo.fluid
    : data.allContentfulScanshipCompanyInfo.edges[0].node
        .darkLogo.fluid;

  useEffect(() => {
    setTimeout(
      () =>
        indicatorRef.current !== null &&
        indicatorRef.current.updateIndicator(),
      1000
    );
  }, []);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [smallScreen]);

  const handleChange = (e, index) => {
    const delay = index === 0 ? 0 : 300;
    index === 0
      ? setSelectedPage(false)
      : setSelectedPage(index);
    setTimeout(
      () => navigate(NAV_ITEMS[index].link),
      delay
    );
  };

  return (
    <>
      {!smallScreen && (
        <div className={classes.navBar}>
          <AppBar
            color="default"
            className={classes.appBar}
          >
            <Tab
              className={classes.logoTab}
              onClick={e => handleChange(e, 0)}
              label={
                <Img
                  fluid={logo}
                  className={classes.logo}
                  alt="Scanship"
                />
              }
            />
            <StyledTabs
              value={selectedPage}
              onChange={handleChange}
              action={indicatorRef}
            >
              {NAV_ITEMS.map((item, i) =>
                i === 0 ? (
                  <Tab
                    style={{ display: 'none' }}
                    key={0}
                    hidden
                  />
                ) : (
                  <Tab
                    key={formatMessage({ id: item.intlId })}
                    label={formatMessage({
                      id: item.intlId,
                    })}
                    className={classes.tab}
                  />
                )
              )}
            </StyledTabs>
          </AppBar>
        </div>
      )}
      {smallScreen && (
        <>
          <AppBar className={classes.appBarSmallDevice}>
            <Toolbar>
              <Button
                onClick={() => navigate(NAV_ITEMS[0].link)}
              >
                <Img
                  fluid={logo}
                  className={classes.logo}
                  alt="Scanship"
                />
              </Button>
              {!open && (
                <IconButton
                  className={classes.openButton}
                  onClick={() => setOpen(true)}
                >
                  <MenuBurger />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          <NavDrawer
            visible={open}
            hide={() => setOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default Nav;

const NavQuery = graphql`
  query NavQuery {
    allContentfulScanshipCompanyInfo {
      edges {
        node {
          logo {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          darkLogo {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
