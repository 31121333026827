export const getRandomBoolean = () =>
  Math.round(Math.random()) === 0 ? false : true;

export const encode = data => {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
};

export const formatPhoneNumber = number => {
  let result = '';
  for (let i = 0; i < number.length; i++) {
    if (i % 3 === 0) {
      result += ` ${number[i]}`;
    } else {
      result += number[i];
    }
  }
  return result;
};
