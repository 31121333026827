import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  string,
  bool,
  object,
  oneOfType,
  element,
} from 'prop-types';
import FONTS from '../constants/fonts';

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: ({ textTransform }) =>
      textTransform && textTransform,
    fontWeight: `${FONTS.WEIGHT.MEDIUM} !important`,
  },
  header: {
    fontSize: FONTS.SIZE.XXXL,
    lineHeight: '90%',
    [theme.breakpoints.down('md')]: {
      fontSize: FONTS.SIZE.XL,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.L,
    },
  },
  subheader: {
    fontSize: FONTS.SIZE.XXXXL,
    fontWeight: `${FONTS.WEIGHT.BOLD} !important`,
    [theme.breakpoints.down('sm')]: {
      fontSize: FONTS.SIZE.XXL,
    },
  },
}));

const Header = ({
  children,
  sub,
  style,
  className,
  textTransform,
}) => {
  const classes = useStyles({ textTransform });

  return (
    <Typography
      style={style}
      className={`${classes.root} ${
        sub ? classes.subheader : classes.header
      } ${className}`}
      variant={sub ? 'h1' : 'h2'}
    >
      {children}
    </Typography>
  );
};

Header.propTypes = {
  children: oneOfType([string, element]).isRequired,
  sub: bool,
  style: object,
  className: string,
  textTransform: string,
};

Header.defaultProps = {
  sub: false,
  style: {},
  className: '',
};

export default Header;
