export default {
  WHITE: '#F8F8F8',
  BLACK: '#141414',
  BLUE: '#268AE5',
  DARK_BLUE: '#001337',
  GREY: '#BBBEC4',
  RED: '#E52648',
  GREEN: '#13D176',
  YELLOW: '#FCE157',
};
