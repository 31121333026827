import React from 'react';
import { Grid } from '@material-ui/core';
import { string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { formatPhoneNumber } from '../../utils/functions';

const useStyles = makeStyles(theme => ({
  OrganisationCard: {
    color: COLORS.WHITE,
  },
  name: { fontWeight: FONTS.WEIGHT.BOLD },
  address: {
    whiteSpace: 'pre-wrap',
    marginBottom: 30,
    wordBreak: 'break-all',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
  },
}));

const OrganisationCard = ({
  name,
  address,
  email,
  phone,
  orgNumber,
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      {...props}
      className={`${classes.OrganisationCard} ${className}`}
    >
      <Grid item xs={12} className={classes.name}>
        {name}
      </Grid>
      <Grid item xs={12} className={classes.address}>
        <a
          href={`https://www.google.se/maps/search/${name} ${address}`}
          target="_blank"
          rel="noreferrer"
        >
          {address}
        </a>
      </Grid>
      {email && (
        <Grid item xs={12}>
          <a href={`mailto:${email}`}>{email}</a>
        </Grid>
      )}
      {phone && (
        <Grid item xs={12}>
          <a href={`tel:${phone}`}>
            {formatPhoneNumber(phone)}
          </a>
        </Grid>
      )}
      {orgNumber && (
        <Grid item xs={12}>
          Org. nr. {orgNumber}
        </Grid>
      )}
    </Grid>
  );
};

OrganisationCard.propTypes = {
  name: string.isRequired,
  address: string.isRequired,
  email: string,
  phone: string,
  orgNumber: string,
  className: string,
};

OrganisationCard.defaultProps = {
  className: '',
};

export default OrganisationCard;
