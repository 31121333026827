import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { string } from 'prop-types';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';

const useStyles = makeStyles({
  root: {
    backgroundColor: COLORS.BLUE,
    color: COLORS.WHITE,
    borderRadius: 0,
    fontWeight: FONTS.WEIGHT.REGULAR,
    fontSize: FONTS.SIZE.XS,
    padding: '5px 8px',
    textTransform: 'none',
    fontFamily: FONTS.FAMILY.PRIMARY,
    '&:hover': {
      backgroundColor: COLORS.BLUE,
      filter: ' brightness(0.95)',
    },
  },
});

const DefaultButton = props => {
  const classes = useStyles();
  return (
    <Button
      {...props}
      className={`${classes.root} ${props.className}`}
    />
  );
};

DefaultButton.propTypes = {
  className: string,
};

DefaultButton.defaultProps = {
  className: '',
};

export default DefaultButton;
