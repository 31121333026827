export default [
  { intlId: 'Nav.home', link: '/' },
  {
    intlId: 'Nav.solutions',
    link: '/solutions',
  },
  {
    intlId: 'Nav.services',
    link: '/services',
  },
  {
    intlId: 'Nav.news',
    link: '/news',
  },
  {
    intlId: 'Nav.aboutUs',
    link: '/about-us',
    subpages: [
      { intlId: 'aboutUs.subnav.index', link: '/about-us' },
      {
        intlId: 'aboutUs.subnav.references',
        link: '/about-us/references',
      },
      {
        intlId: 'aboutUs.subnav.management',
        link: '/about-us/management',
      },
    ],
  },
  {
    intlId: 'Nav.contact',
    link: '/contact',
    subpages: [
      { intlId: 'contact.subnav.index', link: '/contact' },
      {
        intlId: 'contact.subnav.jobOffers',
        link: '/contact/job-offers',
      },
      /*{
        intlId: 'contact.subnav.requestSupport',
        link: '/contact/request-support',
      },*/
    ],
  },
];
